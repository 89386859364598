import React from "react"
// components
import Layout from "../../components/layout-slider"
import Seo from "../../components/seo"
import SliderList from "../../components/sliderListImmo"
// style
import "../../assets/css/slick.css"
import "../../assets/css/slick-theme.css"
import "../../assets/css/slick-overwrites.css"


function HandwerkPage() {

  return (
    <Layout pageNav='immo'>

      <Seo title="Handwerk aus Leidenschaft" />

      <div className="mx-auto text-center">
        <SliderList />
      </div>

    </Layout>
  )
}

export default HandwerkPage